<template lang="html">
  <section class="v-admin-login container-fluid">
    <div class="row justify-content-center">
      <form
        class="loginBO p-fluid p-formgrid p-grid col-12 col-md-7 col-lg-5 col-xxl-3 mt-6 p-4"
        @submit.prevent="login"
      >
        <img :src="imgSRC" class="col-8 mb-4" />
        <span class="p-float-label">
          <InputText id="username" type="text" v-model="form.username" />
          <label for="username">Usuario</label>
        </span>
        <span class="p-float-label mt-4">
          <Password :toggleMask="true" :feedback="false"  id="username" type="text" v-model="form.password" />
          <label for="username">Contraseña</label>
        </span>
        <!-- <vs-checkbox v-model="recordarusuario" class="mt-3">
          Recordar usuario</vs-checkbox
        > -->
        <vs-button class="mt-4 text-lg" size="l" block square type="submit">
          <i class="pi pi-sign-in"></i>&nbsp;Iniciar sesión
        </vs-button>
      </form>
    </div>
  </section>
</template>

<script lang="js">
import API_URL from"../backend.js"
  export default  {
    name: 'v-admin-login',
    props: [],
    mounted () {

    },
    data () {
      return {
        message:'',
        recordarusuario:false,
        imgSRC: API_URL+'/imagenes/extom/horizontal_on_white_by_logaster.jpeg',
        form:{
          username:'',
          password:'',
        }
      }
    },
    methods: {
      login(){
        this.$store.dispatch("auth/login", this.form).then(
          (data) => {
            this.$toast.add({severity:'success', summary: 'Inicio sesión', detail:data.descripcion, life: 7000});
            this.$router.push("/admin/dashboard");
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
              this.$toast.add({severity:'error', summary: 'Error inicio sesión', detail:this.message.descripcion, life: 10000});
          }
        );
      }

    },
    computed: {

    }
}
</script>

<style lang="scss">
.v-admin-login {
  min-height: 90vh;
  background-color: #b4b4b418;
}
.loginBO {
  border-radius: 15px;
  background: white;
}
</style>